import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import CloudflareAppSec from '../../../containers/Services/Cloudflare/AppSec';

const cloudflareData = {
  title: 'Application Security',
  seoParams: {
    ogImagePath: '/og-images/cloudflare-appsec.png',
    ogTitle: 'Application Security Services | Tech Holding',
    ogDesc: 'Protect Your Digital Frontiers with Tech Holding and Cloudflare.',
    ogPath: '/services/cloudflare/appsec',
  },
  sections: [
    {
      subheading: 'Protect Your Digital Frontiers',
      description:
        'Our advanced application security services harness the power of Cloudflare to offer:',
      columns: [
        {
          items: [
            {
              title: 'DDoS Protection',
              content:
                'Guard against the largest and most sophisticated attacks to ensure uninterrupted service.',
            },
            {
              title: 'Web Application Firewall (WAF)',
              content:
                'Shield your applications from malicious threats and attacks with Cloudflare’s adaptive WAF.',
            },
          ],
        },
        {
          items: [
            {
              title: 'Bot Management',
              content:
                'Protect against automated threats with intelligent bot management that differentiates between good and bad traffic.',
            },
            {
              title: 'Turnstile',
              content:
                'Enhance user interactions by replacing traditional CAPTCHAs with a frictionless security solution that doesn’t compromise on protection.',
            },
          ],
        },
      ],
    },
  ],
  whyPartner: {
    heading: 'Why Partner with Tech Holding?',
    subheading: 'Expertise, Support, and Innovation',
    description: 'Choose Tech Holding as your Cloudflare MSSP to benefit from:',
    columns: [
      {
        items: [
          {
            title: 'Tailored Solutions',
            content: 'Custom configurations that address your specific business challenges.',
          },
          {
            title: 'Expert Support',
            content:
              'Access to our team of experts who understand your needs and are ready to assist 24/7.',
          },
        ],
      },
      {
        items: [
          {
            title: 'Strategic Growth',
            content:
              'Innovative solutions that scale with your business, helping you stay ahead in a competitive market.',
          },
        ],
      },
    ],
  },
  callToAction: {
    heading: 'Get Started with Tech Holding Today',
    description:
      'Ready to enhance your business with our Cloudflare solutions? Contact us to discuss your needs or schedule a consultation. Our experts are here to help you navigate your digital transformation journey with confidence.',
  },
};

const propTypes = {
  data: PropTypes.shape({
    contentfulCompanyQuote: PropTypes.shape({
      footerDescription: PropTypes.shape({
        json: PropTypes.shape({}).isRequired,
      }).isRequired,
    }),
  }).isRequired,
};

const AppSecPage = ({ data }) => (
  <CloudflareAppSec details={cloudflareData} companyQuote={data.contentfulCompanyQuote} />
);

AppSecPage.propTypes = propTypes;

export default AppSecPage;

export const query = graphql`
  query {
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      footerDescription {
        json
      }
    }
  }
`;
